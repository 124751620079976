/* eslint-disable */
import axiosInstance from '../axiosInstance'; // Import your configured Axios instance

// Function to add a new study
export const addStudy = async (studyData) => {
  try {
    const response = await axiosInstance.post('/api/study-add', studyData);
    console.log('Study added:', response.data);
  } catch (error) {
    console.error('Error adding study:', error.response.data);
  }
};

export const addStudyImage = async (data) => {
  try {
    // Assuming 'formData' contains files named 'fileJunior' and 'fileSenior'
    const imageRes = await axiosInstance.post('/api/image-add-study', data.formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    const { imageURLJunior, imageURLSenior } = imageRes.data;

    // Create the study data object with image URLs
    let searchDataToAdd = {
      ...data.trademark, // assuming this object contains other necessary fields like juniorName, seniorName, etc.
      juniorImageURL: imageURLJunior,
      seniorImageURL: imageURLSenior,
      localDate: dateToString(new Date()) // Format the date
    };

    console.log('searchDataToAdd', searchDataToAdd);

    // Send the data to the study-add endpoint
    // const response = await axiosInstance.post('/api/study-add', searchDataToAdd);
    return imageRes; // Resolve the Promise with the response data
  } catch (error) {
    console.log({ error });
    throw error; // Reject the Promise with the error
  }
};

// Function to retrieve study data by ID
export const getStudyData = async (studyId) => {
  try {
    const response = await axiosInstance.post('/api/study-data', { _id: studyId });
    console.log('Study data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error retrieving study:', error.response.data);
  }
};

// Function to update a study
export const updateStudy = async (studyId, updateData) => {
  try {
    const response = await axiosInstance.post('/api/study-update', { _id: studyId, ...updateData });
    console.log('Study updated:', response.data);
  } catch (error) {
    console.error('Error updating study:', error.response.data);
  }
};

// Function to delete a study
export const deleteStudy = async (studyId) => {
  try {
    const response = await axiosInstance.post('/api/study-delete', { _id: studyId });
    console.log('Study deleted:', response.data);
  } catch (error) {
    console.error('Error deleting study:', error.response.data);
  }
};

function dateToString(date) {
  // Array of month names
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Get the day of the month and pad with leading zero if necessary
  var day = date.getDate();
  day = day < 10 ? '0' + day : day;

  // Get the full month name
  var month = months[date.getMonth()];

  // Get the year
  var year = date.getFullYear();

  // Format the date string
  var formattedDate = month + ' ' + day + ', ' + year;

  return formattedDate;
}

// Function to retrieve all studies for a specific user by user ID
export const getStudiesByUserId = async (userId) => {
  try {
    const response = await axiosInstance.post(`/api/studies-by-user/${userId}`);
    console.log('Studies for user:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error retrieving studies for user:', error.response ? error.response.data : 'Server error');
    throw error;
  }
};

// Function to add an image related to a study
// export const addStudyImage = async (imageFile) => {
//   const formData = new FormData();
//   formData.append('file', imageFile);

//   try {
//     const response = await axiosInstance.post('/api/image-add-study', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     console.log('Image uploaded:', response.data);
//     return response.data;
//   } catch (error) {
//     console.error('Error uploading image:', error.response.data);
//   }
// };
