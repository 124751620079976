import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import RTLMainLayout from 'layout/RTLMainLayout';
import MainLayout from 'layout/MainLayout';
import Profile from 'pages/profile/index';
import PrivacyPolicy from 'pages/privacy-policy/index';
import TermsOfService from 'pages/terms-of-service/index';
import Watch from 'pages/watch/index';
import Results from 'pages/results/index';
import Study from 'pages/study/index';

const Subscribe = Loadable(lazy(() => import('pages/subscribe')));
const Contact = Loadable(lazy(() => import('pages/contact-us')));
const History = Loadable(lazy(() => import('pages/history')));
const DefaultReport = Loadable(lazy(() => import('pages/default-report')));
// ==============================|| MAIN ROUTING ||============================== //

const SubscriptionRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/contact',
      element: <Contact />
    },
    {
      path: '/',
      element: <Subscribe />
    },
    {
      path: '/subscribe',
      element: <Subscribe />
    },
    {
      path: '/watch',
      element: <Watch />
    },
    {
      path: '/watch/:id',
      element: <Watch />
    },
    {
      path: 'study',
      element: <Study />
    },
    {
      path: '/history',
      element: <History />
    },
    {
      path: 'search-results/:id',
      element: <Results />
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: '/templates',
      element: <DefaultReport />
    },

    {
      path: '*',
      element: <Subscribe />
    },
    {
      path: 'profile',
      element: <Profile />
    }
  ]
};

export default SubscriptionRoutes;
