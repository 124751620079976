/* eslint-disable */
// import { logoutUser } from 'actions/authActions';
import axios from 'axios';
import { store } from 'store';
// In your Axios configuration file
import { setSessionTimeout } from './store/sessionSlice'; // import the action

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:3001'
  baseURL: process.env.REACT_APP_BACKEND_URL
});
const token = localStorage.getItem('jwtToken');
// console.log('test ', token);
// Using Axios interceptors to append the token to every GET request
axiosInstance.interceptors.request.use(
  (config) => {
    // Check if it's a GET request and a token exists
    if (config.method != 'post' && (config.method === 'get' || config.method === 'GET') && token) {
      const separator = config.url.includes('?') ? '&' : '?';
      config.url += `${separator}token=${encodeURIComponent(token)}`;
      console.log(config.url);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && error.response.data.message === 'Invalid session. Please log in again.') {
      // store.dispatch(logoutUser());
      store.dispatch(setSessionTimeout(true));
      // window.location = '/login';
      return Promise.reject(error);
    }
    // Handle other errors
    return Promise.reject(error);
  }
);

export default axiosInstance;
