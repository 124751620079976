/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Stack, StepLabel, Stepper, Step, Autocomplete, TextField } from '@mui/material';
import { connect } from 'react-redux';
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';
import ImageDisplay from 'components/ImageDisplay';
import ModernImageInput from 'components/ImageInput';
import { addStudyImage } from 'actions/studies';
import Chip from 'themes/overrides/Chip';
import { CloseOutlined } from '@ant-design/icons';
import LightboxGallery from 'components/LightboxGallery';
import EditableLabel from 'components/EditableLabel';

const steps = ['Senior', 'Junior', 'Review your Submit'];

const ValidationWizard = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [imageSenior, setImageSenior] = useState(null);
  const [croppedImageUrlSenior, setCroppedImageUrlSenior] = useState('');
  const [isSeniorImageUploaded, setIsSeniorImageUploaded] = useState(false);
  const [isJuniorImageUploaded, setIsJuniorImageUploaded] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [labelText, setLabelText] = useState('Junior-' + dateToString(new Date()));
  const [labelTextSenior, setLabelTextSenior] = useState('Senior-' + dateToString(new Date()));

  function dateToString(date) {
    // Array of month names
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Get the day of the month and pad with leading zero if necessary
    var day = date.getDate();
    day = day < 10 ? '0' + day : day;

    // Get the full month name
    var month = months[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Format the date string
    var formattedDate = month + ' ' + day + ', ' + year;

    return formattedDate;
  }

  const items = [
    'Chemicals',
    'Paints',
    'Cosmetics and Cleaning Preparations',
    'Lubricants and Fuels',
    'Pharmaceuticals',
    'Metal Goods',
    'Machinery',
    'Hand Tools',
    'Electrical and Scientific Apparatus',
    'Medical Apparatus',
    'Environmental Control Apparatus',
    'Vehicles',
    'Firearms',
    'Jewelry',
    'Musical Instruments',
    'Paper Goods and Printed Matter',
    'Rubber Goods',
    'Leather Goods',
    'Non-metallic Building Materials',
    'Furniture and Articles Not Otherwise Classified',
    'Housewares and Glass',
    'Cordage and Fibers',
    'Yarns and Threads',
    'Fabrics',
    'Clothing',
    'Fancy Goods',
    'Floor Coverings',
    'Toys and Sporting Goods',
    'Meats and Processed Foods',
    'Staple Foods',
    'Natural Agricultural Products',
    'Light Beverages',
    'Wines and Spirits',
    "Smokers' Articles",
    'Advertising and Business',
    'Insurance and Financial',
    'Construction and Repair',
    'Communication',
    'Transportation and Storage',
    'Material Treatment',
    'Education and Entertainment',
    'Scientific, computer and legal services',
    'Hotel and restaurant services',
    'Medical, beauty and agricultural services',
    'Personal services'
  ];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      uploadFile();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid item xs={12} md={6}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              <Button onClick={() => setCroppedImageUrl(null)}>X</Button>
            </Box>
            <ModernImageInput
              imageName="senior"
              setImage={setImage}
              croppedImageUrl={croppedImageUrl}
              setCroppedImageUrl={(url) => {
                setCroppedImageUrl(url);
                setIsSeniorImageUploaded(!!url);
              }}
              image={image?.result ? image?.result : image}
            />
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12} md={6}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              <Button onClick={() => setCroppedImageUrlSenior(null)}>X</Button>
            </Box>
            <ModernImageInput
              imageName="junior"
              setImage={setImageSenior}
              croppedImageUrl={croppedImageUrlSenior}
              setCroppedImageUrl={(url) => {
                setCroppedImageUrlSenior(url);
                setIsJuniorImageUploaded(!!url);
              }}
              image={imageSenior?.result ? imageSenior?.result : imageSenior}
            />
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={5} display="flex" justifyContent="center" alignItems={'center'}>
            <Grid item xs={6} display="flex" justifyContent="center" alignItems={'center'}>
              <LightboxGallery image={image} />
              <EditableLabel labelText={labelText} onChange={setLabelText} />
              {/* <ImageDisplay src={image} /> */}
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" alignItems={'center'}>
              <LightboxGallery image={imageSenior} />
              <EditableLabel labelText={labelTextSenior} onChange={setLabelTextSenior} />
              {/* <ImageDisplay src={imageSenior} /> */}
            </Grid>
            <Grid xs={12} mt={7} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Box width={'100%'} maxWidth={800}>
                <Autocomplete
                  multiple
                  variant="outlined"
                  fullWidth
                  options={items}
                  getOptionLabel={(label) => label}
                  onChange={(event, newValue) => setSelectedClasses(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="skill"
                      placeholder="Select Classes"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderColor: '#2321b',
                          borderWidth: '2px',
                          borderStyle: 'groove',
                          padding: '3px 8px',
                          '&:hover': {
                            borderColor: '#2321b'
                          },
                          '&.Mui-focused': {
                            borderColor: '#2321b'
                          },
                          '& .MuiAutocomplete-tag': {
                            margin: '1px'
                          }
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                          '&:hover': {
                            background: 'none'
                          }
                        },

                        '& .MuiAutocomplete-clearIndicator': {
                          '&:hover': {
                            background: 'none'
                          }
                        },
                        '& fieldset': {
                          display: 'none'
                        }
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        );
      default:
        throw new Error('Unknown step');
    }
  };

  const formatDateToCustomFormat = () => {
    const date = new Date();
    const timestamp = date.getTime();
    return timestamp;
  };

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const tempExt = mime.split('/');
    const extension = tempExt.length > 1 ? tempExt[1] : tempExt[0];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + extension, { type: mime });
  };

  const uploadFile = async () => {
    if (croppedImageUrl && croppedImageUrlSenior) {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append(
          'fileJunior',
          dataURLtoFile(croppedImageUrl.result ? croppedImageUrl.result : croppedImageUrl, `${new Date().getTime()}.`)
        );
        formData.append(
          'fileSenior',
          dataURLtoFile(croppedImageUrlSenior.result ? croppedImageUrlSenior.result : croppedImageUrlSenior, `${new Date().getTime()}.`)
        );

        // Append additional fields from data
        formData.append('userID', props.auth.user.id);
        formData.append('classes', JSON.stringify(selectedClasses)); // Assuming selectedClasses is an array
        formData.append('label', formatDateToCustomFormat());
        formData.append('juniorName', labelText);
        formData.append('seniorName', labelTextSenior);

        let data = {
          formData: formData,
          trademark: {
            userID: props.auth.user.id,
            classes: selectedClasses,
            seniorName: labelTextSenior,
            juniorName: labelText,
            label: formatDateToCustomFormat()
          }
        };

        const result = await addStudyImage(data).catch((err) => {
          console.log({ err });
          setLoading(false);
        });
        console.log({ result });
        location.href = result.data.sessionUrl;
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      alert('Please ensure that you upload both images');
    }
  };

  return (
    <MainCard>
      <Box sx={{ mb: 7, width: '100%' }}>
        <Typography variant="h5">Image Study Analysis</Typography>
        <Typography variant="subtitle1">Secure and Insightful Image Comparison at Your Fingertips</Typography>
      </Box>
      <Stepper activeStep={activeStep} sx={{ pb: 5 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        {activeStep === steps.length ? (
          <>
            <Typography variant="h5" gutterBottom>
              Thank you for your order.
            </Typography>
            <Typography variant="subtitle1">
              Your order number is #2001539. We have emailed your order confirmation, and will send you an update when your order has
              shipped.
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setActiveStep(0);
                  }}
                  sx={{ my: 3, ml: 1 }}
                >
                  Reset
                </Button>
              </AnimateButton>
            </Stack>
          </>
        ) : (
          <>
            <Grid container display="flex" justifyContent="center">
              {getStepContent(activeStep)}
            </Grid>

            <Stack direction="row" justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              <AnimateButton>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ my: 3, ml: 1 }}
                  disabled={
                    (activeStep === 0 && !isSeniorImageUploaded) ||
                    (activeStep === 1 && !isJuniorImageUploaded) ||
                    (activeStep === 2 && selectedClasses.length === 0)
                  }
                >
                  {activeStep === steps.length - 1 ? 'Run Study' : 'Next'}
                </Button>
              </AnimateButton>
            </Stack>
          </>
        )}
      </>
    </MainCard>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { addStudyImage })(ValidationWizard);
