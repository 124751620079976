/* eslint-disable */
import {
  SearchOutlined,
  DotChartOutlined,
  HistoryOutlined,
  UserOutlined,
  GlobalOutlined,
  FileTextOutlined,
  EyeOutlined
} from '@ant-design/icons';

const icons = {
  SearchOutlined,
  HistoryOutlined,
  UserOutlined,
  GlobalOutlined,
  FileTextOutlined,
  EyeOutlined,
  DotChartOutlined
};

const home = {
  id: 'group-home',
  title: '',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Search',
      type: 'item',
      url: '/home',
      icon: icons.SearchOutlined,
      breadcrumbs: false
    },
    {
      id: 'watch',
      title: 'Watch',
      type: 'item',
      url: '/watch',
      icon: icons.EyeOutlined,
      breadcrumbs: false
    },
    {
      id: 'study',
      title: 'Study',
      type: 'item',
      url: '/study',
      icon: icons.DotChartOutlined,
      breadcrumbs: false
    },

    {
      id: 'history',
      title: 'History',
      type: 'item',
      url: '/history',
      icon: icons.HistoryOutlined,
      breadcrumbs: false
    },
    {
      id: 'defaultSpecs',
      title: 'Templates',
      type: 'item',
      url: '/templates',
      icon: icons.FileTextOutlined,
      breadcrumbs: false
    },
    {
      id: 'profile',
      title: 'Profile',
      type: 'item',
      url: '/profile',
      icon: icons.UserOutlined,
      breadcrumbs: false
    }
  ]
};

export default home;
